<template>
<div class="p-grid p-fluid home">
    <div class="home">
        <div class="features" style="text-align: center;background-image: url('./images/prefondo4.png');">
            <h5 style="color:#ffcc2a;">{{$t('Test Cotización')}}</h5>
            <div class="p-grid" >
                <div class="p-col-12 p-md-6">
                    <div class="feature-card">
                        <div class="feature-card-detail">
							<br>
                            <div class="feature-name">{{$t('Información del Vehículo')}}</div>
							<div class="p-fluid p-formgrid p-grid" style="text-align: left;">
								<div class="p-field p-col-12 p-md-4">
									<label for="state">{{$t('Cliente')}}</label>
									<Dropdown v-model="vehiculo.tipocliente" :options="tipoclientes" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Act_Pol()"></Dropdown>
									<small v-show="validationErrors.tipocliente" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-3">
									<label>{{$t('Año de Referencia')}}</label>
									<InputMask v-model="vehiculo.year" mask="9999" @change="Act_Pol()"/>
									<small v-show="validationErrors.year" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-5">
									<label for="state">{{$t('Tipo Póliza')}}</label>
									<div v-if="vehiculo.tipocliente && vehiculo.year">
										<Dropdown v-model="vehiculo.tipopoliza" :options="tipopolizas.filter(val => val.tipocliente == vehiculo.tipocliente.code && (val.year_veh_min == 0 || parseInt($store.state.year.code-vehiculo.year+1)<=val.year_veh_min))" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Act_Veh()"></Dropdown>
										<!--
										<Dropdown v-model="vehiculo.tipopoliza" :options="tipopolizas.filter(val => val.tipocliente == vehiculo.tipocliente.code && val.code == 1)" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Act_Veh()" v-if="parseInt(vehiculo.year)<parseInt($store.state.year.code-6)"></Dropdown>
										<Dropdown v-model="vehiculo.tipopoliza" :options="tipopolizas.filter(val => val.tipocliente == vehiculo.tipocliente.code)" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Act_Veh()" v-else></Dropdown>
										-->
										<small v-show="validationErrors.tipopoliza" class="p-error">{{$t('required')}}</small>
									</div>
								</div>
								<div class="p-field p-col-12 p-md-4">
									<label>{{$t('Fecha de nacimiento')}}</label>
									<InputMask v-model="vehiculo.fecha" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
									<!--<InputText v-model="vehiculo.fecha" type="date"/>-->
									<small v-show="validationErrors.fecha" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-8">
									<label for="state">{{$t('Tipo de Vehículo')}}</label>
									<Dropdown v-model="vehiculo.tipo" :options="tipos.filter(val => val.tipocliente == vehiculo.tipocliente.code && val.tipopoliza == vehiculo.tipopoliza.code)" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="vehiculo.tipopoliza"></Dropdown>
									<small v-show="validationErrors.tipo" class="p-error">{{$t('required')}}</small>
								</div>
								
								<div class="p-field p-col-12 p-md-6">
									<label for="state">{{$t('Marca')}}</label>
									<Dropdown v-model="vehiculo.marca" :options="marcas" optionLabel="nombre" :filter="true" placeholder="...?" @change="vermodelos()">
										<template #value="slotProps">
											<div class="p-dropdown-car-value" v-if="slotProps.value">
												<span> {{slotProps.value.nombre}}</span>
											</div>
											<span v-else>
												{{slotProps.placeholder}}
											</span>
										</template>
										<template #option="slotProps">
											<div class="p-dropdown-car-option">
												<span> {{slotProps.option.nombre}}</span>
											</div>
										</template>
									</Dropdown>
									<small v-show="validationErrors.marca" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="state">{{$t('Módelo')}}</label>
									<Dropdown v-model="vehiculo.modelo" :options="modelos" optionLabel="nombre" :filter="true" placeholder="...?">
										<template #value="slotProps">
											<div class="p-dropdown-car-value" v-if="slotProps.value">
												<span> {{slotProps.value.nombre}}</span>
											</div>
											<span v-else>
												{{slotProps.placeholder}}
											</span>
										</template>
										<template #option="slotProps">
											<div class="p-dropdown-car-option">
												<span> {{slotProps.option.nombre}}</span>
											</div>
										</template>
									</Dropdown>
									<small v-show="validationErrors.modelo" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-3">
									<label>{{$t('Nº asientos')}}</label>
									<InputNumber v-model="vehiculo.asientos" min="1" max="100" integeronly showButtons />
									<small v-show="validationErrors.asientos" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-5">
									<label>{{$t('Posición del Volante')}}</label>
									<SelectButton v-model="vehiculo.volante" :options="options" />
								</div>
								<div class="p-field p-col-12 p-md-4">
									<label>{{$t('Costo')}}</label>
									<InputText v-model="kosten" type="number" min="0" disabled/>
								</div>
								<!--
								<div class="p-field p-col-12 p-md-4">
									<label for="state">{{$t('Sin reclamación')}}</label>
									<Dropdown v-model="noclaim" :options="noclaims" optionLabel="nombre" placeholder="Select..."></Dropdown>
								</div>
								-->	
								<div class="p-field p-col-6 p-md-2">
									<label for="state">{{$t('Sin reclamación')}}</label>
									<div v-if='vehiculo.no_claim'>{{ vehiculo.no_claim.noclaim }}%</div>
								</div>	
								<div class="p-field p-col-6 p-md-3">
									<label>No claim years</label>
									<InputText v-model="vehiculo.antiguedad" type="number" min="0"/>
								</div>
								<div class="p-field p-col-6 p-md-3">
									<label for="state">{{$t('Descuento Especial')}}</label>
									<Dropdown v-model="descuento" :options="descuentos" optionLabel="nombre" placeholder="Select..."></Dropdown>
								</div>	
								<div class="p-field p-col-6 p-md-2">
									<label for="state">{{$t('Ajuste')}}</label>
									<div>{{moneda(parseFloat(adjustment).toFixed(2))}}</div>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="state">{{$t('Duración')}}</label>
									<Dropdown v-model="duracion" :options="duracions" :optionLabel="$t('nombre')" placeholder="Select..." @change="Duracion()"></Dropdown>
									<small v-show="validationErrors.duracion" class="p-error">{{$t('required')}}</small>
								</div>	
								<div class="p-field p-col-12 p-md-6">
									<label for="state">{{$t('Pagos')}}</label>
									<Dropdown v-model="cuota" :options="cuotas_opc" :optionLabel="$t('nombre')" placeholder="Select..." v-if="duracion"></Dropdown>
									<small v-show="validationErrors.cuota" class="p-error">{{$t('required')}}</small>
								</div>
								<div class="p-field p-col-12 p-md-8">
									<label for="state">{{$t('Adicional')}}</label>
									<Button label="Clean up"  class="p-button-danger p-button-rounded p-button-text" v-if="adicional.length>0" @click="adicional=[]" v-tooltip.bottom="$t('Ninguna')" style="width: 30%;"/>
									<MultiSelect v-model="adicional" :options="adicionales" :optionLabel="$t('nombre')" alls="All" placeholder="...?" class="multiselect-custom">
										<template #option="slotProps">
											<div class="country-item country-item-value">
												<div>{{ slotProps.option.name }} - {{moneda(parseFloat(slotProps.option.valor).toFixed(2))}}</div>
											</div>
										</template>				
									</MultiSelect>
								</div>
								<div class="p-field p-col-12 p-md-4">
									<!--
									<label for="state">{{$t('Estimación')}}</label>
									<InputText v-model="estimate" type="number"  step="0.01" min="0" :max="monto"/>
									-->
								</div>
							</div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-6">
                    <div class="feature-card">
                        <div class="feature-card-detail">
							<br>
                            <div class="feature-name">
								{{$t('Estimación')}}
								({{$t('Duración')}}:
									<strong v-if="i18n.locale() == 'es'">12 meses</strong>
									<strong v-else>12 months</strong>)
							</div>
							<div class="p-fluid p-formgrid p-grid">
								<br><br>
								<div class="p-col-6" align="left">
									<strong>{{$t('Catálogo')}}:</strong>
								</div>
								<div class="p-col-6" align="left">
									<div v-if="mostrar && i18n.locale() == 'es'">
										{{tarifa.nombre}}
									</div>
									<div v-if="mostrar && i18n.locale() == 'en'">
										{{tarifa.name}}
									</div>
								</div>
								<div class="p-col-6" align="left">
									<strong>{{$t('Año de Catálogo')}}:</strong>
								</div>
								<div class="p-col-6" align="left">
									<div v-if="mostrar">{{catalogo_year}}</div>
								</div>
								<br><br>
								<div class="p-col-6" align="left">
									<strong>{{$t('Valor de Catálogo')}}:</strong>
								</div>
								<div class="p-col-6" align="left">
									<div  v-if="mostrar">
										{{moneda(parseFloat(catalogo_vehiculo).toFixed(2))}}
									</div>
								</div>
								<div class="p-col-6" align="left">
									<strong>{{$t('Deducible')}}:</strong>
								</div>
								<div class="p-col-6" align="left">
									<div  v-if="mostrar">
										{{moneda(parseFloat(deductible).toFixed(2))}}
									</div>
								</div>								<br><br>
								<div class="p-col-6" align="left">
									<strong>{{$t('Cobertura')}}:</strong>
								</div>
								<div class="p-col-6" align="left">
									<div v-if="mostrar">
										{{moneda(parseFloat(primabruta).toFixed(2))}}
										({{$t('Factor')+': '+parseFloat(factorlimite).toFixed(4)}})
									</div>
								</div>
								<br><br>
								<div class="p-col-6" align="left">
									<strong>{{'Factor '+$t('Casco')}}:</strong>
								</div>
								<div class="p-col-6" align="left">
									<div v-if="mostrar">
										{{parseFloat(factor).toFixed(4)}}
									</div>
								</div>
								<br><br>
								<div class="p-col-2" align="left">
									<strong>{{$t('Recargo')}}:</strong>
								</div>
								<div class="p-col-4" align="left">
									<div v-if="mostrar" style="font-size: 12px;">
										{{$t('Asientos')}}: {{moneda(parseFloat(adic_asientos).toFixed(2))}}
									</div>
								</div>
								<div class="p-col-6" align="left">
									<div v-if="mostrar" style="font-size: 12px;">
										{{$t('Año')+' '+$t('Vehículo')}}: {{moneda(parseFloat(recarga_veh).toFixed(2))}}
									</div>
								</div>
								<div class="p-col-2"></div>
								<div class="p-col-4" align="left">
									<div v-if="mostrar" style="font-size: 12px;">
										{{$t('Edad')}}: {{moneda(parseFloat(recarga_edad).toFixed(2))}}
									</div>
								</div>
								<div class="p-col-6" align="left">
									<div v-if="mostrar" style="font-size: 12px;">
										{{$t('Volante')}}: {{moneda(parseFloat(recarga_volante).toFixed(2))}}
									</div>
								</div>
								<br><br>
								<div class="p-col-6" align="left">
									<strong>{{$t('Prima Bruta')}}: </strong>
								</div>
								<div class="p-col-6" align="left">
									<strong v-if="mostrar">
										{{moneda(parseFloat(primabruta).toFixed(2))}}
									</strong>
								</div>

								<div class="p-col-6" align="left">
									<strong>{{$t('Prima Bruta')}} + {{$t('Recargo')}}: </strong> 
								</div>
								<div class="p-col-6" align="left">
									<strong v-if="mostrar">{{moneda(parseFloat(subtotal).toFixed(2))}}</strong> 
								</div>
								<br><br>

								<div class="p-col-6" align="left">
									<strong>{{$t('Sin reclamación')}} WA : </strong> 
								</div>
								<div class="p-col-6" align="left">
									<strong v-if="mostrar">{{moneda(parseFloat(c_noclaim).toFixed(2))}}</strong> 
								</div>
								<div class="p-col-6" align="left">
									<strong>{{$t('Descuento Especial')}} WA : </strong> 
								</div>
								<div class="p-col-6" align="left">
									<strong v-if="mostrar">{{moneda(parseFloat(c_descuento).toFixed(2))}}</strong> 
								</div>
								<div class="p-col-6" align="left">
									<strong>{{$t('Adicional')}} : </strong> 
								</div>
								<div class="p-col-6" align="left">
									<strong v-if="mostrar">{{moneda(parseFloat(adic).toFixed(2))}}</strong> 
								</div>							
								<br><br>
								<div class="p-col-6" align="left">
									<h6><strong>{{$t('Subtotal')}}: </strong> </h6>
								</div>
								<div class="p-col-6" align="left">
									<h6><strong v-if="mostrar">{{moneda(parseFloat(subtotal-c_noclaim-c_descuento+adic).toFixed(2))}}</strong></h6> 
								</div>
								<div class="p-col-6" align="left">
									<strong>{{$t('Forma de pago')}} : </strong> 
								</div>
								<div class="p-col-6" align="left">
									<strong v-if="mostrar">{{moneda(parseFloat((subtotal-c_noclaim-c_descuento+adic)*rec_cuota/100).toFixed(2))}}</strong> 
								</div>
								<br><br>
								<div class="p-col-6" align="left">
									<h6><strong>{{$t('Premie WA')}}: </strong> </h6>
								</div>
								<div class="p-col-6" align="left">
									<h6><strong v-if="mostrar">{{moneda(parseFloat(premie).toFixed(2))}}</strong></h6> 
								</div>
								<br><br>
								<div class="p-col-6" align="left">
									<strong>{{$t('Costo')}}: </strong>
								</div>
								<div class="p-col-6" align="left">
									<strong v-if="mostrar">
										{{moneda(parseFloat(kosten).toFixed(2))}}
									</strong>
								</div>
								<br><br>
								<div class="p-col-6" align="left">
									<strong>{{$t('Impuesto')}}: </strong> 
								</div>
								<div class="p-col-6" align="left">
									<strong v-if="mostrar">{{moneda(parseFloat(impuesto).toFixed(2))}}</strong> 
								</div>
								<br><br>
								<div class="p-col-6" align="left" v-if="cuota && mostrar">
									<strong v-if="cuota.valor>1">{{$t('Cuota')}}: </strong> 
								</div>
								<div class="p-col-6" align="left" v-if="cuota && mostrar">
									<strong v-if="cuota.valor>1">{{moneda(parseFloat(monto/cuota.valor))}}</strong> 
								</div>
								<br><br>
								<div class="p-col-6" align="left">
									<h5><strong>{{$t('Monto')}}</strong>:</h5>
								</div>								
								<div class="p-col-6" align="left">
									<h5><strong v-if="mostrar">{{moneda(parseFloat(monto).toFixed(2))}}</strong></h5>									
								</div>
								<br><br>
							</div>
                            <Button :disabled ="$store.state.loading" :label="$t('Calcular Cotización')" icon="bi bi-calculator" class="p-mr-2 p-mb-2" @click="Calcular()"></Button>
                            <Button :disabled ="$store.state.loading" :label="$t('PDF')" icon="pi pi-file-pdf" class="p-mr-2 p-mb-2" @click="PDF()" style="width: 20%;" v-if="monto"></Button>
                        </div>
                    </div>
                </div>
			</div>
        </div>
    </div>
</div>

<Register :display="displayRegister" @aprob-login="onMenuToggle"  @close-register="close"/>


</template>

<script>
import API from "@/service/API";
const Consulta = new API('Simulador','Simulador');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import Register from '../components/Register.vue';
import PDF from "../service/PDF";

export default {
	components: {
        Register,
    },
    data () {
        return {
            submitted: false,
            validationErrors: {},
			marcas: [],
			modelos: [],
			years: [],
			tipos: null,
			tipoclientes: null,
			vehiculo: {
				marca: null,
				modelo: null,
				compra: null,
				automatico: true,
				placa: null,
				motor: null,
				chasis: null,
				year: null,
				asientos: 1,
				color: null,
				tipo: null,
				adverso: null,
				reverso: null,
				cardreg: null,
				cardemi: null,
				cardvec: null,
				volante: '<- Left',
				tipopoliza: null,
				fecha: null,
				tipocliente: null,
				antiguedad: 0,
				no_claim: null,
			},
            Dialog: false,
            size: '40vw',
			foto: false,
			img: null,
			options: ['<- Left', 'Right->'],
			tipopolizas: [],
			displayRegister: false,
			tarifa: {
				nombre: null,
				name: null,
				valor: 0
			},
			mostrar: false,
			catalogo_year: null,
			catalogo_vehiculo: 0,
			primabruta: 0,
			deductible: 0,
			factor_asientos: 0,
			adic_asientos: 0,
			factor: 0,
			factorlimite: 0,
			recarga_veh: 0,
			recarga_edad: 0,
			recarga_volante: 0,
			kosten: 0,
			subtotal: 0,
			impuesto: 0,
			monto: 0,
			monto2: 0,
			monto3: 0,
			limite: 0,
			duracions: [],
            cuotas: [],
			duracion: null,
            cuota: null,
			cuotas_opc: [],
			rec_cuota: 0,
			premie: 0,
			noclaims: [],
			descuentos: [],
			noclaim: null,
			descuento: null,
			c_noclaim: 0,
			c_descuento: 0,
			adicionales: [],
			adicional: [],
			adic: 0,
			adjustment: 0,
			estimate: 0
        }
    },
	created() {
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
        this.url = url;
		Consulta.Ini().then(result => {
			//this.$store.state.error = result;
			this.marcas = result.consult.marcas;
			this.tipos = result.consult.tipos;
			this.tipopolizas = result.consult.tipopolizas;
			this.tipoclientes = result.consult.tipoclientes; 
			//this.kosten = result.consult.valores.costo1;  
			this.duracions = result.consult.duracion;  
			this.cuotas = result.consult.cuotas;  
			this.noclaims = result.consult.noclaims;
			this.descuentos = result.consult.descuentos;
			this.noclaim = result.consult.noclaims[0];
			this.descuento = result.consult.descuentos[0];
			this.adicionales = result.consult.adicionales;
		}); 
	},
    methods: {
		PDF(){
			var veh = this.vehiculo;
			veh.kosten = this.kosten;
			veh.noclaim = this.vehiculo.no_claim;
			veh.duracion = this.duracion;
			veh.descuento = this.descuento;
			veh.cuota = this.cuota;
			var estim = {
				tarifa: this.tarifa,
				catalogo_year: this.catalogo_year,
				catalogo_vehiculo: this.moneda(parseFloat(this.catalogo_vehiculo).toFixed(2)),
				deductible: this.moneda(parseFloat(this.deductible).toFixed(2)),
				primabruta: this.moneda(parseFloat(this.primabruta).toFixed(2)),
				factorlimite: parseFloat(this.factorlimite).toFixed(4),
				factor: parseFloat(this.factor).toFixed(4),
				adic_asientos: this.moneda(parseFloat(this.adic_asientos).toFixed(2)),
				recarga_veh: this.moneda(parseFloat(this.recarga_veh).toFixed(2)),
				recarga_edad: this.moneda(parseFloat(this.recarga_edad).toFixed(2)),
				recarga_volante: this.moneda(parseFloat(this.recarga_volante).toFixed(2)),
				subtotal: this.moneda(parseFloat(this.subtotal).toFixed(2)),
				noclaim: this.moneda(parseFloat(this.c_noclaim).toFixed(2)),
				pago: this.moneda(parseFloat(((this.subtotal-this.c_noclaim-this.c_descuento)*this.rec_cuota/100)).toFixed(2)),
				descuento: this.moneda(parseFloat(this.c_descuento).toFixed(2)),
				adic: this.moneda(parseFloat(this.adic).toFixed(2)),
				premie: this.moneda(parseFloat(this.premie).toFixed(2)),
				kosten: this.moneda(parseFloat(this.kosten).toFixed(2)),
				impuesto: this.moneda(parseFloat(this.impuesto).toFixed(2)),
				installment: this.moneda(parseFloat(this.monto/veh.cuota.valor).toFixed(2)),
				monto: this.moneda(parseFloat(this.monto).toFixed(2)),
			};
			const doc = new PDF();
			doc.Check(veh,estim);
		},
		Registrarse() {
            this.$store.commit('Mobile');
            this.displayRegister = true;
        },
		Contacto() {
            this.$router.push({ path: '/contact'});
        },
        close() {
            this.displayRegister = false;
        },
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		Act_Pol(){
			this.vehiculo.tipopoliza = null;
		},
		Act_Veh(){
			this.vehiculo.tipo = null;
		},
		Adicional(){
			var adicional = 0;
			this.adicional.forEach(element => {
				adicional = adicional + parseFloat(element.valor);
			});
			this.adic = adicional;
		},
		Duracion(){
			if(this.duracion){
				this.cuota = null;
				this.cuotas_opc = [];
				switch (this.duracion.code) {
					case 1:
						this.cuotas_opc = this.cuotas.filter(val => val.code<=1);
						break;	
					case 2:
						this.cuotas_opc = this.cuotas.filter(val => val.code==0 || val.code==3);
						break;	
					case 3:
						this.cuotas_opc = this.cuotas.filter(val => val.code==0 || val.code==3);
						break;	
					default:
						this.cuotas_opc = this.cuotas;
				}		
				if(!this.cuotas_opc.includes(this.cuotas)){
					this.cuota = this.cuotas_opc[0];
				}
				this.kosten = this.duracion.cost;
			}	
		},
        Calcular() {
            if (this.validateForm()) {
				this.Estimate();
				this.mostrar = false;
				this.Adicional();
				var fechanac = Consulta.fdate(this.vehiculo.fecha);
				Consulta.Procesar('Calcular',{
					vehiculo: this.vehiculo,
					fechanac: fechanac,
					adjustment:this. adjustment,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.vehiculo.no_claim = response.result.noclaim;
						this.tarifa = response.result.calculo.tarifa;
						this.catalogo_year = response.result.calculo.catalogo_year.nombre;
						this.catalogo_vehiculo = response.result.calculo.catalogo_vehiculo;
						this.primabruta = response.result.calculo.primabruta;
						this.adic_asientos = response.result.calculo.adic_asientos;
						this.recarga_veh = response.result.calculo.recarga_veh;
						this.recarga_edad = response.result.calculo.recarga_edad;
						this.recarga_volante = response.result.calculo.recarga_volante;
						this.limite = response.result.calculo.limite;
						this.factor = response.result.calculo.factor;
						this.factorlimite = response.result.calculo.factorlimite;
						this.subtotal = response.result.calculo.subtotal;
						this.deductible = response.result.calculo.deductible;
						//var noclaim =  parseFloat(this.subtotal*this.noclaim.valor);
						var noclaim =  parseFloat(this.subtotal*response.result.noclaim.factor*(-1));
						this.c_noclaim = noclaim;
						var descuento =  parseFloat(this.subtotal*this.descuento.valor);
						this.c_descuento = descuento;
						this.rec_cuota = this.cuota.porcentaje;
						var premie = parseFloat(((this.subtotal-noclaim-descuento+this.adic)*(1+this.rec_cuota/100))*this.duracion.valor);
						this.premie = premie;
						var impuesto = parseFloat(premie)+parseFloat(this.kosten);
						impuesto = impuesto *  parseFloat(response.result.calculo.impuesto);
						this.impuesto = impuesto;
						var monto = parseFloat(premie+impuesto)+parseFloat(this.kosten);
						this.monto = monto;
						this.mostrar = true;
					}
				});
				
            }
        },
		Estimate() {
			if(this.estimate>0 && this.monto>0 && this.estimate<=this.monto){
				let adjustment = 0;
				let T = parseFloat(this.estimate);
				let C = parseFloat(this.kosten);
				let Im = parseFloat(this.impuesto)/(parseFloat(this.premie)+parseFloat(this.kosten));
				let R = parseFloat(this.rec_cuota/100);
				let D = parseFloat(this.duracion.valor);
				let A = parseFloat(this.adic);
				let fa = parseFloat(this.adic_asientos);
				let G = parseFloat(this.primabruta);	
				let P_E = parseFloat(this.recarga_edad)/parseFloat(this.primabruta);
				let P_Ve = parseFloat(this.recarga_veh)/parseFloat(this.primabruta);
				let P_Vo = parseFloat(this.recarga_volante)/parseFloat(this.primabruta);
				let NC = parseFloat(this.c_noclaim)/parseFloat(this.subtotal);
				let De = parseFloat(this.c_descuento)/parseFloat(this.subtotal);
				adjustment = T / ( 1 + Im ) ;
				adjustment = adjustment - C ;
				adjustment = adjustment / ( ( 1 + R ) * D ) ;
				adjustment = adjustment - A ;
				adjustment = adjustment / ( 1 - De + NC );
				adjustment = adjustment - fa;
				adjustment = adjustment / ( 1 + P_E + P_Ve + P_Vo );
				adjustment = adjustment - G;
				adjustment = adjustment.toFixed(2);
				if(parseFloat(this.estimate).toFixed(1) != parseFloat(this.monto).toFixed(1)){
					this.adjustment = adjustment;
				}
			} else { 
				this.adjustment = 0;
				this.estimate = 0;
			}
		},
        vermodelos() {
			if(this.vehiculo.marca.code>0){
				this.modelos = [];
				this.years = [];
				this.vehiculo.modelo = null;
				//this.vehiculo.year = null;
				Consulta.Procesar('vermodelos',{
					marca: this.vehiculo.marca.code,
				}).then(response => {
					if (response.result){
						this.modelos = response.result;
					}					
				});
			}
        },
        veryear() {
			if(this.vehiculo.modelo.code>0){
				this.years = [];
				//this.vehiculo.year = null;
				Consulta.Procesar('veryear',{
					modelo: this.vehiculo.modelo.code,
				}).then(response => {
					if (response.result){
						this.years = response.result;
					}						           
				});
			}
        },
        Carnet() {
            this.Dialog = true;
        },
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency:this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
        validateForm() {
            if (!this.vehiculo.marca)
               this.validationErrors['marca'] = true;
            else
               delete this.validationErrors['marca'];
            if (!this.vehiculo.modelo)
               this.validationErrors['modelo'] = true;
            else
               delete this.validationErrors['modelo'];
            if (!this.vehiculo.tipo)
               this.validationErrors['tipo'] = true;
            else
               delete this.validationErrors['tipo'];
            if (!this.vehiculo.year)
               this.validationErrors['year'] = true;
            else
               delete this.validationErrors['year'];
            if (!this.vehiculo.asientos)
               this.validationErrors['asientos'] = true;
            else
               delete this.validationErrors['asientos'];
            if (!this.vehiculo.fecha)
               this.validationErrors['fecha'] = true;
            else
               delete this.validationErrors['fecha'];
            if (!this.vehiculo.tipopoliza)
               this.validationErrors['tipopoliza'] = true;
            else
               delete this.validationErrors['tipopoliza'];
            if (!this.vehiculo.tipocliente)
               this.validationErrors['tipocliente'] = true;
            else
               delete this.validationErrors['tipocliente'];
			if (!this.duracion)
               this.validationErrors['duracion'] = true;
            else
               delete this.validationErrors['duracion'];
			if (!this.cuota)
               this.validationErrors['cuota'] = true;
            else
               delete this.validationErrors['cuota'];

            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>